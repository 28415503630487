<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs>
      <b-breadcrumb-item :text="`Management - ${$store.state.settings.app.current.title}`"/>
      <b-breadcrumb-item text="Invoices" :to="{ name: 'management-invoices' }" />
      <b-breadcrumb-item v-if="invoice" :text="`${invoice.student.name.first} ${invoice.student.name.last}`" :to="{ name: 'management-invoice', params: { id: invoice.id }}" />
      <b-breadcrumb-item text="Print" active />
    </template>
    <template #actions="{ }">
      <b-button title="Print" variant="transparent" size="sm" @click="windowPrint">
        <font-awesome-icon icon="fa-solid fa-print" />
      </b-button>
    </template>
    <template #dropdown-options="{ }">
      <b-dropdown-group header="Invoice To">
        <b-dropdown-item @click.native.capture.stop="config.invoiceToSchool = !config.invoiceToSchool">
          <font-awesome-icon :icon="`fa-solid fa-${config.invoiceToSchool ? 'toggle-on' : 'toggle-off'}`"/>
          <span class="align-middle ml-50">School</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="config.invoiceToSchool" @click.native.capture.stop="config.invoiceToSchoolLegal = !config.invoiceToSchoolLegal">
          <font-awesome-icon :icon="`fa-solid fa-${config.invoiceToSchoolLegal ? 'toggle-on' : 'toggle-off'}`"/>
          <span class="align-middle ml-50">Legal Name</span>
        </b-dropdown-item>
      </b-dropdown-group>
      <b-dropdown-divider/>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content>
      <template v-if="error">
        <b-alert id="student-error" show variant="danger">
          <p>
            <b-icon-exclamation-circle class="mr-25"/>
            {{ error }}
          </p>
        </b-alert>
      </template>
      <template v-else>
        <b-alert show dismissible variant="primary" class="d-print-none">
          <b-icon-info-circle class="mr-50"/>
          This view is readonly. To make changes, please use the <u><b-link :to="{ name: 'management-invoice', params: { id: id }}">Invoice</b-link></u> view.
        </b-alert>

        <b-row>
          <b-col cols="3" class="d-print-none">
            <!-- Filters & Paging -->
            <b-card-actions title="Options" action-collapse header-class="py-50">
              <b-row>
                <template>
                  <b-col cols="6" align-self="center">
                    <label for="config-invoice-to-school">Invoice To School</label>
                  </b-col>
                  <b-col cols="6" align-self="start" class="d-flex justify-content-end pr-50">
                    <b-checkbox id="config-invoice-to-school" v-model="config.invoiceToSchool" switch inline size="sm"/>
                  </b-col>
                </template>
                <template v-if="config.invoiceToSchool">
                  <b-col cols="6" align-self="center">
                    <label for="config-invoice-use-school-legal">Use School Legal Name</label>
                  </b-col>
                  <b-col cols="6" align-self="start" class="d-flex justify-content-end pr-50">
                    <b-checkbox id="config-invoice-use-school-legal" v-model="config.invoiceToSchoolLegal" switch inline size="sm"/>
                  </b-col>
                </template>
                <template>
                  <b-col cols="12">
                    <hr class="mt-2"/>
                  </b-col>
                </template>
                <template>
                  <b-col cols="6" align-self="center">
                    <label for="marks-page">Show Page Marks</label>
                  </b-col>
                  <b-col cols="6" align-self="start" class="d-flex justify-content-end pr-50">
                    <b-checkbox id="marks-page" v-model="config.showPageMarks" switch inline size="sm"/>
                  </b-col>
                </template>
                <template>
                  <b-col cols="6" align-self="center">
                    <label for="marks-fold">Show Fold Marks</label>
                  </b-col>
                  <b-col cols="6" align-self="start" class="d-flex justify-content-end pr-50">
                    <b-checkbox id="marks-fold" v-model="config.showFoldMarks" switch inline size="sm"/>
                  </b-col>
                </template>
                <template>
                  <b-col cols="6" align-self="center">
                    <label for="marks-window">Show Window Marks</label>
                  </b-col>
                  <b-col cols="6" align-self="start" class="d-flex justify-content-end pr-50">
                    <b-checkbox id="marks-window" v-model="config.showWindowMarks" switch inline size="sm"/>
                  </b-col>
                </template>
              </b-row>
            </b-card-actions>
            <!-- Print -->
            <b-button block variant="primary" @click="windowPrint()">
              <font-awesome-icon icon="fa-solid fa-print" />
              <span class="align-middle ml-50">Print</span>
            </b-button>
          </b-col>
          <b-col cols="9">
            <div v-if="invoice" class="letter-container">
              <div class="letter">
                <div class="letter-header">

                  <img :src="blueWhiteGold" alt="Logo" class="letter-logo">

                  <div class="return-address">
                    <div class="font-weight-bold">New York State School Music Association</div>
                    <div>718 The Plain Road</div>
                    <div>Westbury, NY 11590-5931</div>
                    <div>Phone: (516) 997-7200</div>
                    <div>Fax: (516) 997-1700</div>
                  </div>
                </div>
                <div class="recipient-address" :class="config.showWindowMarks ? 'window-mark' : ''">
                  <template v-if="config.invoiceToSchool">

                    <template v-if="config.invoiceToSchoolLegal">
                      <div v-if="hasValue(invoice, 'student.school.name')">{{ invoice.student.school.name.legal }}</div>
                      <div v-else class="text-danger">No School Name</div>
                    </template>
                    <template v-else>
                      <div v-if="hasValue(invoice, 'student.school.name')">{{ invoice.student.school.name.popular }}</div>
                      <div v-else class="text-danger">No School Name</div>
                    </template>
                    <div>Accounts Payable</div>
                    <div>
                      <template v-if="hasValue(invoice, 'student.school.address')">
                        <span>{{ invoice.student.school.address.line1 }}</span>
                        <span v-if="invoice.student.school.address.line2">, {{ invoice.student.school.address.line2 }}</span>
                        <div>{{ invoice.student.school.address.city }}, {{ invoice.student.school.address.state }} {{ invoice.student.school.address.zip }}</div>
                      </template>
                      <template v-else>
                        <span class="text-danger">No School Address</span>
                      </template>
                    </div>
                  </template>
                  <template v-if="!config.invoiceToSchool">
                    <div>{{ invoice.student.name.first }} {{ invoice.student.name.last }}</div>
                    <div>
                      <span>{{ invoice.student.address.line1 }}</span>
                      <span v-if="invoice.student.address.line2">, {{ invoice.student.address.line2 }}</span>
                    </div>
                    <div>{{ invoice.student.address.city }}, {{ invoice.student.address.state }} {{ invoice.student.address.zip }}</div>
                  </template>
                </div>
                <div class="letter-body">
                  <b-row class="invoice-spacing m-0">
                    <!-- Invoice To -->
                    <b-col cols="6" class="p-0 d-flex justify-content-start">
                      <div class="invoice-details-wrapper">
                        <h6 id="invoice-title" class="mb-1 font-weight-bolder line-height-1">
                          All-State Invoice
                        </h6>
                        <div id="invoice-number" class="invoice-date-wrapper">
                          <span class="invoice-date-title">Invoice #</span>
                          <span class="invoice-date text-right">{{ invoice.number }}</span>
                        </div>
                        <div id="invoice-for" class="invoice-date-wrapper">
                          <span class="invoice-date-title">Invoice For:</span>
                          <span class="invoice-date text-right">{{ invoice.student.name.first }} {{ invoice.student.name.last }}</span>
                        </div>
                        <div id="invoice-date-issued" class="invoice-date-wrapper">
                          <span class="invoice-date-title">Date Issued:</span>
                          <span class="invoice-date">{{ invoice.createdAt | date }}</span>
                        </div>
                        <div id="invoice-date-modified" class="invoice-date-wrapper">
                          <span class="invoice-date-title">Date Modified:</span>
                          <span class="invoice-date">{{ invoice.updatedAt | date }}</span>
                        </div>
                      </div>
                    </b-col>
                    <!-- Payment Details -->
                    <b-col cols="6" class="p-0 d-flex justify-content-end">
                      <div class="invoice-total-wrapper">
                        <div id="fee-nyssma" class="invoice-total-item">
                          <p class="invoice-total-title">Registration Fee:</p>
                          <p class="invoice-total-amount font-weight-normal">{{ setting.fees.nyssma | currency }}</p>
                        </div>
                        <div id="fee-hotel" class="invoice-total-item">
                          <p class="invoice-total-title">Room and Board:</p>
                          <p class="invoice-total-amount font-weight-normal">{{ setting.fees.hotel | currency }}</p>
                        </div>
                        <hr>
                        <div id="payments-total-due" class="invoice-total-item">
                          <p class="invoice-total-title">Total Due:</p>
                          <p class="invoice-total-amount font-weight-normal">{{ totalCost | currency }}</p>
                        </div>
                        <div id="payments-paid" class="invoice-total-item">
                          <p class="invoice-total-title">Paid:</p>
                          <p class="invoice-total-amount font-weight-normal">{{ totalPaid | currency }}</p>
                        </div>
                        <div id="payments-pending" class="invoice-total-item">
                          <p class="invoice-total-title">Pending:</p>
                          <p class="invoice-total-amount font-weight-normal">{{ totalPending | currency }}</p>
                        </div>
                        <div id="payments-returns" class="invoice-total-item">
                          <p class="invoice-total-title">Returns:</p>
                          <p class="invoice-total-amount font-weight-normal">
                            {{ totalReturns > 0 ? '-' : null }}{{ totalReturns | currency }}
                          </p>
                        </div>
                        <hr class="my-50">
                        <div id="payments-remaining" class="invoice-total-item">
                          <p class="invoice-total-title">Remaining Due:</p>
                          <p class="invoice-total-amount">{{ totalDue | currency }}</p>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <hr class="mb-2">
                  <b-row class="p-0">
                    <b-col cols="12">
                      <div class="font-weight-bolder mb-1">Selected Applications</div>
                      <b-table responsive="md"
                               head-variant="light"
                               :items="invoice.student.applications.items"
                               :fields="[
                                  { key: 'instrument.name', label: 'Instrument' },
                                  { key: 'selection.ensemble.name', label: 'Ensemble' },
                                  { key: 'selection.part', label: 'Part' },
                                  { key: 'selection.accepted', label: 'Accepted' }
                                ]"
                               show-empty empty-text="No Applications have been Selected for this Student">
                        <template #cell(instrument.name)="data">
                          <span v-if="hasValue(data, 'item.instrument')">{{ data.item.instrument.name }}</span>
                          <span v-else class="text-danger">No Instrument</span>
                        </template>
                        <template #cell(selection.ensemble.name)="data">
                          <span v-if="hasValue(data, 'item.selection.ensemble')">{{ data.item.selection.ensemble.name }}</span>
                          <span v-else class="text-danger">No Ensemble</span>
                        </template>
                        <template #cell(selection.accepted)="data">
                          {{ getAcceptedText(data.item.selection) }}
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                  <hr class="mb-2">
                  <b-row class="p-0">
                    <b-col cols="12">
                      <div class="font-weight-bolder mb-1">Payments</div>
                      <b-table responsive="md"
                               :items="invoice.payments.items"
                               :fields="[ { key: 'type' }, { key: 'check', label: 'Check Number' }, { key: 'amount' }, { key: 'received' }, { key: 'returned' } ]"
                               show-empty empty-text="No Payments or Purchase Orders have been associated to this invoice.">
                        <template #cell(type)="data">
                          <b-card-text class="mb-25">
                            {{ data.item.type }}
                          </b-card-text>
                        </template>
                        <template #cell(amount)="data">
                          <b-card-text class="mb-25">
                            {{ data.item.amount | currency }}
                          </b-card-text>
                        </template>
                        <template #cell(received)="data">
                          <b-card-text class="mb-25">
                            {{ data.item.received | date }}
                          </b-card-text>
                        </template>
                        <template #cell(returned)="data">
                          <b-card-text class="mb-25">
                            {{ data.item.returned | date }}
                          </b-card-text>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="config.showPageMarks || config.showFoldMarks" class="fold-guide">
                  <div v-if="config.showPageMarks" class="fold-mark page-start"></div>
                  <div v-if="config.showFoldMarks" class="fold-mark fold-mark-1"></div>
                  <div v-if="config.showFoldMarks" class="fold-mark fold-mark-2"></div>
                  <div v-if="config.showPageMarks" class="fold-mark page-end"></div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </template>
    </template>
  </page-layout>
</template>
<script>
import PageLayout from '@/components/PageLayout.vue';
import print from '@/mixins/print.mixin';
import {API, graphqlOperation} from 'aws-amplify';
import { getInvoice, getSetting } from './invoice';
import blueWhiteGold from '@/assets/images/logo/logo5-blue-white-gold.svg';
import PaymentAmount from '@/components/PaymentAmount.vue';
import InvoiceHeaderLeft from './InvoiceHeaderLeft.vue';
import InvoiceTo from '@/views/management/billing/InvoiceTo.vue';
import InvoicePaymentRow from './InvoicePaymentRow.vue';
import InvoicePaymentModal from './InvoicePaymentModal.vue';
import billing from './billing.mixin';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import lodashMixin from '@/mixins/lodash.mixin';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  components: {
    BCardActions,
    InvoiceTo,
    InvoicePaymentModal,
    InvoicePaymentRow,
    PaymentAmount,
    InvoiceHeaderLeft,
    PageLayout,
  },
  mixins: [print, billing, lodashMixin, settingsMixin],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    invoiceToSchool: {
      type: Boolean,
      default: true
    },
    invoiceToSchoolLegal: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      setting: {
        fees: {
          nyssma: 0,
          hotel: 0
        },
      },
      config: {
        showFoldMarks: true,
        showPageMarks: false,
        showWindowMarks: false,
        invoiceToSchool: this.invoiceToSchool,
        invoiceToSchoolLegal: this.invoiceToSchoolLegal,
      },
      invoice: null,
      error: null,
      subscriptions: {
        onCreate: null,
        onUpdate: null,
        onDelete: null
      },
      rules: {
        money: { required: true, numeric: true, min_value: 1}
      },
      icon: 'fa-file-invoice-dollar',
      blueWhiteGold: blueWhiteGold,
    }
  },
  computed: {
    payments() {
      return this.invoice?.payments.items || []
    },
    totalCost() {
      return this.setting.fees.nyssma + this.setting.fees.hotel
    },
    totalPaid() {
      let paid = 0
      if(this.invoice) {
        this.invoice.payments.items.filter(payment => payment.received && !payment.returned).forEach(payment => {
          paid += payment.amount
        })
      }
      return paid
    },
    totalPending() {
      let pending = 0
      if(this.invoice) {
        this.invoice.payments.items.filter(payment => !payment.received && payment.returned === null).forEach(payment => {
          pending += payment.amount
        })
      }
      return pending
    },
    totalReturns() {
      let returns = 0
      if(this.invoice) {
        this.invoice.payments.items.filter(payment => payment.returned).forEach(payment => {
          returns += payment.amount
        })
      }
      return returns
    },
    totalDue() {
      return this.totalCost - this.totalPaid
    },
    hasOverpaid() {
      if(this.invoice) {
        if(this.totalPaid > this.totalCost) {
          return true
        }
      }
      return false
    },
    hasUnderpaid() {
      if(this.invoice) {
        if(this.totalPaid < this.totalCost) {
          return true
        }
      }
      return false
    },
    isPaid() {
      if(this.invoice) {
        if(this.totalPaid === this.totalCost) {
          return true
        }
      }
      return false
    },
    hasPendingPayments() {
      if(this.invoice) {
        if(this.invoice.payments.items.some(payment => !payment.received && payment.returned === null)) {
          return true
        }
      }
      return false
    },
    shouldRefund() {
      if(this.invoice) {
        if(this.invoice?.student?.applications.items.every(app => app.selection?.accepted !== true)) {
          if(this.invoice.payments.items.length > 0 && this.invoice.payments.items.every(payment => !payment?.returned) && this.totalPaid > 0) {
            return true
          }
        }
      }
      return false
    }
  },
  async created() {
    await this.getSetting();
    await this.getInvoice();
  },
  mounted() {
    this.$refs.layout.state.loading = false
  },
  methods: {
    async getSetting() {
      const response = await API.graphql(graphqlOperation(getSetting, { key: 'billing' }));
      this.setting = response.data.getSetting
      if(this.setting) {
        this.setting = JSON.parse(this.setting.value)
      }
      this.loading = false
    },
    async getInvoice() {
      const response = await API.graphql(graphqlOperation(getInvoice, { id: this.id }));
      this.invoice = response.data.getInvoice
      if(this.invoice) {
        /** Check Current Year **/
        if(!this.isCreatedAtInCurrentYear(this.invoice.createdAt)) {
          this.error = `You are currently viewing ${this.$store.state.settings.app.current.title} data. This invoice was created in ${new Date(this.invoice.createdAt).getFullYear()}. Switch to that year to view this record.`
        }
      }
      else {
        this.error = 'Invoice not found.'
      }
    },
    async refresh() {
      this.$refs.layout.state.loading = true
      this.error = null
      await this.getInvoice()
      this.$refs.layout.state.loading = false
    },
    getAcceptedVariant(selection) {
      if(!selection) return 'dark'
      return selection.accepted ? 'success' : 'danger'
    },
    getAcceptedText(selection) {
      if(!selection) return 'No Selection'
      if(selection.accepted === null) return 'Undecided'
      return selection.accepted ? 'Yes' : 'No'
    },
    windowPrint() {
      window.print()
    },
  }
}
</script>

<style lang="scss" scoped>
//letters.css
/* Scaling for smaller screens */
@media (max-width: 1600px) {
  .letter-container {
    transform: scale(90.5%);
    transform-origin: left top;
    -webkit-transform-origin: left top;
  }
  .vertical-menu-modern.menu-collapsed .letter-container {
    transform: none;
    transform-origin: initial;
    -webkit-transform-origin: initial;
  }
}
@media (max-width: 1536px) {
  .letter-container {
    transform: scale(86%);
    transform-origin: left top;
    -webkit-transform-origin: left top;
  }
  .vertical-menu-modern.menu-collapsed .letter-container {
    transform: scale(98.6%);
    transform-origin: left top;
    -webkit-transform-origin: left top;
  }
}
@media (max-width: 1440px) {
  .letter-container {
    transform: scale(79%);
    transform-origin: left top;
    -webkit-transform-origin: left top;
  }
  .vertical-menu-modern.menu-collapsed .letter-container {
    transform: scale(91.75%);
    transform-origin: left top;
    -webkit-transform-origin: left top;
  }
}
@media (max-width: 1366px) {
  .letter-container {
    transform: scale(73.9%);
    transform-origin: left top;
    -webkit-transform-origin: left top;
  }
  .vertical-menu-modern.menu-collapsed .letter-container {
    transform: scale(86.5%);
    transform-origin: left top;
    -webkit-transform-origin: left top;
  }
}
@media (max-width: 1280px) {
  .letter-container {
    transform: scale(67.75%);
    transform-origin: left top;
    -webkit-transform-origin: left top;
  }
  .vertical-menu-modern.menu-collapsed .letter-container {
    transform: scale(80.5%);
    transform-origin: left top;
    -webkit-transform-origin: left top;
  }
}

@media print {
  .letter-container {
    transform: none!important;
    transform-origin: initial!important;
    -webkit-transform-origin: initial!important;
  }
}


/* Hide everything outside of the container when printing */
@media print {
  @page {
    size: letter portrait!important;
    margin: 0;
  }

  body {
    margin: 0;
    padding: 0;
    min-width: unset !important;
  }

  .content-header {
    display: none;
  }

  body *:not(.letter-container, .letter-container *) {
    visibility: hidden;
    padding: 0!important;
    margin: 0!important;
    width: unset!important;
    min-width: unset!important;
    max-width: unset!important;
    border: 0;
  }
  .letter-container, .letter-container * {
    visibility: visible!important;
    /*border: 0!important;*/
    box-shadow: unset;
  }

  .letter-container, .certificate-body * {
    border: 0!important;
  }
}

.letter-container {
  display: flex;
  flex-wrap: wrap;
  min-width: 8.5in!important;
  width: 8.5in!important;
  max-width: 8.5in!important;
  margin: 0; /* Set margin to 0.25 inches */
  box-sizing: border-box; /* Make sure margin is included in the container's width */
  justify-content: space-between;
}

/*.letter-editor *:not(:is(.letter-body *)){
	border: 1px dashed #ccc;
}*/

.page-item.prev-item {
  margin-right: 0.3571rem;
  width: 0;
}
.page-item.next-item {
  margin-left: 0.3571rem;
  width: 0;
}

//letter.css
.hover-border {
  background: rgba(33, 41, 105, 0.12) !important
}
.letter {
  width: 8.5in;
  min-height: 11in;
  box-sizing: border-box;
  position: relative;
  font-family: 'Times New Roman', serif;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  margin-bottom: 1rem;
}

@media print {
  .letter {
    border: unset;
    margin-bottom: unset;
    /*page-break-after: always; !* for older browsers *!
    break-after: page; !* modern standard *!*/
  }
}


.fold-guide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /*height: 100%;*/
}

.letter-header {
  display: flex;
  justify-content: space-between;
  margin-top: 0.875in;
  margin-left: 0.875in;
  margin-right: 0.875in;
}

.letter-logo {
  width: 1.25in;
  height: 1.25in;
  border-radius: 50%;
  object-fit: cover;
  background: #fff;
}

.return-address {
  //font-size: 12pt;
  margin: auto 0 auto 0;
  text-align: right;
}

.recipient-address {
  position: absolute;
  /*top: 2.5625in;*/
  top: 2.5in;
  left: 0.875in;
  width: 3.625in;
  height: 0.75in;
  //font-size: 12pt;
  line-height: 1.25;
  word-wrap: break-word;
}

/*.letter-body {
    position: absolute;
    top: 4in;
    !*margin-top: 2.25in;*!
    margin-left: 0.875in;
    margin-right: 0.875in;
    font-size: 12pt;
    line-height: 1.5;
}*/

.letter-body {
  margin: 2in 0.875in 0 0.875in;
  //font-size: 12pt;
  line-height: 1.5;
}


.fold-mark {
  position: absolute;
  width: 100%;
  border-top: 1px dashed #ccc;
}
.fold-mark-1 {
  top: 3.67in;
}
.fold-mark-2 {
  top: 7.33in;
}

.page-start{
  top: 0;
  border-top: 1px dashed red;
}
.page-end {
  top: 11in;
  border-top: 1px dashed red;
}

.window-mark {
  border: 1px dashed red;
}

@media print {
  .fold-mark {
    display: none;
  }
  .window-mark {
    border: 0;
  }
}


//@import "~@core/scss/base/pages/app-invoice.scss";
.invoice-title {
  font-size: 1.285rem;
  margin-bottom: 1rem;
  .invoice-number {
    font-weight: 600;
  }
}
.invoice-date-wrapper {
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }

  .invoice-date-title {
    width: 7rem;
    margin-bottom: 0;
  }
  .invoice-date {
    margin-left: 0.5rem;
    font-weight: 600;
    margin-bottom: 0;
  }
}
/*.invoice-spacing {
  margin: $invoice-spacing;
}*/
.invoice-number-date {
  .title {
    width: 115px;
  }
}
.invoice-total-wrapper {
  width: 100%;
  max-width: 12rem;
  .invoice-total-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .invoice-total-title {
      margin-bottom: 0.35rem;
    }
    .invoice-total-amount {
      margin-bottom: 0.35rem;
      font-weight: 600;
    }
  }
}

//invoice.css
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
    font-size: 12px;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
  a:link { text-decoration: none; color: #6e6b7b; }
  a:visited { text-decoration: none; color: #6e6b7b; }
  a:hover { text-decoration: none; color: #6e6b7b; }
  a:active { text-decoration: none; color: #6e6b7b; }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

.invoice-total-wrapper {
  width: 100%;
  max-width: 20rem!important;
}

.invoice-details-wrapper {
  width: 100%;
  max-width: 20rem!important;
}


.invoice-preview .invoice-date-wrapper .invoice-date-title,
.invoice-edit .invoice-date-wrapper .invoice-date-title,
.invoice-add .invoice-date-wrapper .invoice-date-title {
  width: unset;
}

.invoice-date-wrapper {
  justify-content: space-between;
}

.invoice-date {
  margin: 0 !important;
}

.input-group-prepend .input-group-text {
  background-color: #efefef;
}

.flatpickr-input[disabled] {
  background-color: #efefef!important;
}

.form-control[readonly] {
  background-color: #fff;
}
</style>

